import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/FAQ/PageContents"
import { createGlobalStyle } from "styled-components"
import { ButtonStyle } from "../components/Core/Buttons/Styles"
import colors from "../styles/colors"

const GlobalStyle = createGlobalStyle`
  .gladlyHC-faq {
    padding-top: 100px;

    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .gladlyHC {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .gladlyHC-faqHeading, .gladlyHC-faqSection-header, .gladlyHC-searchResultsSection-heading {
    font-family: adobe-garamond-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    line-height: 1.2;
  }

  .gladlyHC-faqHeading {
    padding-top: 30px;
  }

  .gladlyHC-faqSection-header, .gladlyHC-searchResultsSection-heading {
    padding-top: 50px;
    padding-bottom: 30px;

    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .gladlyHC-faqHeading {
    font-size: 40px;
  }

  .gladlyHC-faqSection-listItem, .gladlyHC-answersIndex-item, .gladlyHC-searchResultsSection-result {
    margin-bottom: 20px;
  }

  .gladlyHC-faqSection-listItem a, .gladlyHC-answersIndex-item a, a.gladlyHC-answerDetail-backLink, .gladlyHC-searchMenuContainer a, .gladlyHC-searchResultsSection-result a {
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 1.2;
    max-width: 600px;
    padding-right: 25px;
    text-decoration: none;
    color: inherit;
  }

  .gladlyHC-answersIndex-link a {
    ${ButtonStyle};
  }

  .gladlyHC-faqSection-listItem-more a, a.gladlyHC-answerDetail-backLink {
    font-family: sofia-pro,sans-serif;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    color: ${colors.black};
    font-size: 12px;
    letter-spacing: 0.94px;
    line-height: 1.07;
    text-transform: uppercase;
    transition: all 0.25s;
    cursor: pointer;
    padding-bottom: 7px;
    border-bottom: 3px solid ${colors.black};
    width: fit-content;
    padding-right: 0;
  }

  .gladlyHC-answerDetail-name {
    font-family: adobe-garamond-pro, serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 1.2;
    padding-top: 30px;
  }

  .gladlyHC-answerDetail-content {
    padding: 30px 0px 10px;
    height: 100%;
    font-size: 16px;
    line-height: 1.33;
  }

  .gladlyHC-searchInput {
    font-size: 20px;
    font-family: "Sofia Pro", sans-serif;
  }
`

const FaqPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data

  const { metaTitle, metaDescription, socialMediaImage, blocks } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout transparentHeader={true} gladlyHCRender={true}>
      <GlobalStyle />
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <PageContents blocks={blocks} />
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqPageQuery {
    page: contentfulPage(slug: { eq: "faq" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      blocks {
        ... heroFragment
        ... quizCalloutFragment
        ... faqFragment
      }
    }
  }
`
